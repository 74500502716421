.details .info {
  user-select: none;
  display: flex
}

.info {
  font-size: 6mm;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: white;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  text-align: center;
}

.device {
  position: absolute;
  background-size: 100% 100%;

  .screen {
    background-color: black;
    position: absolute;
    background-size: 100% 100%;
    image-rendering: pixelated;
  }

  &.closed .screen:not(.closed) {
    display: none;
  }

  &:not(.closed) .closed {
    display: none;
  }
  .caption {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.none {
  .screen {
    display: none;
  }
}

.top-buttons {
  padding: 0;
  border: none;
  position:absolute;
  flex-direction: row;
  align-items:flex-start;
  justify-content: start;
  display: none;
  height: 1.5cm;
  right: 5mm;
  top: 5mm;
}

.bottom-buttons {
  padding: 0;
  border: none;
  position:absolute;
  flex-direction: row;
  align-items:flex-start;
  justify-content: start;
  display: none;
  height: 1.5cm;
  left: 5mm;
  bottom: 5mm;
}

.screen-buttons {
  padding: 0;
  border: none;
  position:absolute;
  flex-direction: row;
  align-items:flex-start;
  justify-content: start;
  height: 1.5cm;
  top: 1mm;
  right: 1mm;
}

.button {
  font-size: 10mm;
  padding: 2mm;
  background-color: lightgray;
  color: #555;
  border: none;
  border-radius: 3px;
  width: 1.5cm;
  height: 1.5cm;
  background-size: contain;
  margin-left: 2mm;
}

.remove-button > div {
  margin-top: -1.5mm;
}

.rotate-button > div {
  margin-top: -2mm;
}

.device:hover .buttons {
  display: flex;
}

.button:hover {
  background-color: white;
  color: black;
}

.closed .open-button {
  background-image: url("/images/open-icon.svg");
}
.open-button {
  background-image: url("/images/close-icon.svg");
}
