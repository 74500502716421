.side-bar {
  z-index: 10009;
  position: fixed;
  right: 0;
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  touch-action: initial;

  &>div {
    min-width: 175px;
  }

  &.top {
    top:0;
  }

  &.bottom {
    bottom:0;
  }

  /* move top bars to bottom on mobile */
  @media only screen and (max-width: 768px) {
    &.top {
      top: initial;
      bottom: 0;
    }
  }


  &.left {
    align-items: flex-start;
    right: auto;
    left: 0;
  }
  &.middle {
    align-items: center;
    width: 50%;
    right: 25%;
    left: 25%;
    z-index: 99999;
  }
}
/* no room for details on mobile */
@media only screen and (max-width: 768px) {
  .side-bar.bottom.middle {
    display: none;
  }
}


.text-row {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 2px;
}

.text-row.border + .text-row.border {
  border-top: lightgray 1px solid;
}

@media only screen and (max-width: 768px) {
  .panel.logo {
    display: none;
  }
}

#logo {
  background-image: url("/images/logo.webp");
  background-size: contain;
  background-repeat: no-repeat;
  width: 270px;
  height: 80px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-style: italic;
  color: transparent;
  font-size: 2em;
}

.middle > .panel {
  width: 100%;
  max-width: none;
}

.panel {
  border-radius: 7px;
  max-width: 250px;
  display:flex;
  margin: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  background-color: white;

  h3 {
    margin: 5px;
  }

  .inside {
    width: 100%;
    min-width: 250px;
  }

  &.closed .inside {
    display: none;
  }

  &.wide {
    max-width: 600px;
  }

  @media only screen and (max-width: 768px) {

    &.controls {
      display: none;
    }

    /* on mobile all panels are 'modals' */
    &:not(.closed) {
      z-index: 10005;
      max-width: 600px;
      position: fixed;
      top: 5px !important;
      bottom: auto;
      background: white;
      padding: 15px;
    }
  }

  &.blank-slate:not(.closed) {
    position: fixed;
    @media only screen and (min-width: 768px) {
      margin: auto;
      left: 100px;
    }
    top: 100px;
    background: white;
    padding: 15px;
  }
}

.small-button {
  font-size: 5mm;
  border-radius: 4px;
  border: none;
  margin: 5px;
  text-align: center;
  padding: 0;
  width: 7mm;
  height: 7mm;
  color: #555;
  background: none;
  &:hover {
    background-color: lightgray;
  }
}

.closed .expander-button::after {
  content: "˅";
}

.expander-button::after {
  content: "˄";
}

.bottom .closed .expander-button::after {
  content: "˄";
}

.bottom .expander-button::after {
  content: "˅";
}

.text-button {
  padding: 8px;
  margin: 5px;
}

.button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.small-text-button {
  padding:3px;
  margin: 3px;
  font-size: small;
}

.device-picker {
  .device-list {
    max-height: 150px;
    overflow-y: auto;


  }

  .search-bar {
    display:flex;
    flex-direction: row;
    justify-content: space-between;


    input[type=text] {
      padding: 3px 6px;
      margin: 5px;
      margin-right: 0;
      border: none;
      font-size: 14px;
      width: 100%;
      border: 1px solid gainsboro;
    }
  }

}

.changelog {
  max-height: 600px;
  overflow-y: auto;

  h2 {
    margin-top: 10px;
  }
  .change-date {
    margin-top: 5px;
    border-top: 1px solid lightgray;
    padding-bottom: 10px;
  }
  .change {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    margin-left: 15px;
    margin-bottom: 5px;

    .pill {
      border-radius: 15px;
      font-size: 12px;
      vertical-align: middle;
      padding: 2px 6px;
      margin-right: 13px;
      width: 50px;
      position: relative;
      text-align: center;
      flex-shrink: 0;

      &.improved {
        background-color: rgb(194, 194, 255);
        color: black;
        &::after {
          content: "Improve";
        }
      }
      &.added {
        background-color: rgb(106, 230, 106);
        color: black;
        &::after {
          content: "Added";
        }
      }
      &.display {
        background-color: rgb(255, 223, 162);
        color: black;
        &::after {
          content: "Display";
        }
      }
      &.device {
        background-color: rgb(251, 251, 128);
        color: black;
        &::after {
          content: "Device";
        }
      }
      &.fixed {
        background-color: rgb(255, 182, 182);
        color: black;
        &::after {
          content: "Fixed";
        }
      }
      &.refactored {
        background-color: rgb(222, 222, 222);
        color: black;
        &::after {
          content: "Coding";
        }
      }
    }
  }
}
