body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #555;
  overflow: hidden;
  height: 100vh;
  margin: 0;
}
body, #backdrop, #background {
  touch-action: none;
}
#backdrop {
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  margin: 0;
}
#background {
  transform-origin: top left;
  background-color: beige;
  position:absolute;
  width:2000mm;
  height:1500mm;
}
